<template>
  <div>
    <!-- Table Container Card -->
    <user-add-new
      :is-add-user-sidebar-active.sync="isAddUserSidebarActive"
      @refetch-data="refetchData"
      v-if="isAddUserSidebarActive"
    />
    <edit-category
      :is-edit-user-category-active.sync="isEditUserCategoryActive"
      @refetch-data="refetchData"
      :user="userRef"
      v-if="isEditUserCategoryActive"
    />

    <edit-user
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :user="userRef"
      @refetch-data="refetchData"
      v-if="isEditUserSidebarActive"
    />
    <edit-user-role-and-permission
      :is-edit-user-role-and-permission-sidebar-active.sync="
        isEditUserRoleAndPermissionSidebarActive
      "
      :user="userRef"
      @refetch-data="refetchData"
      v-if="isEditUserRoleAndPermissionSidebarActive"
    />
    <div style="display: flex; justify-content: flex-end; margin: 20px 0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="isAddUserSidebarActive = true"
        variant="primary"
      >
        {{ $t("Add user") }}
      </b-button>
    </div>
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <!-- Field: Ref -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Role')" label-for="roles">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roles"
              v-model="selectedRole"
              input-id="roles"
              label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          {{ data.item.first_name + " " + data.item.last_name }}
        </template>
        <template #cell(sales_department)="data">
          <b-modal
            :ref="'sales-department-modal-' + data.index"
            :title="$t('Sales Department')"
            hide-footer
          >
            <div class="m-1">
              <h4>Categories :</h4>
              <ul>
                <li v-for="cate in data.item.categories">
                  {{ cate.name }}
                </li>
              </ul>
              <h4>Sub Profiles :</h4>
              <ul>
                <li v-for="sub in data.item.sub_profiles">
                  {{ sub.name }}
                </li>
              </ul>
            </div>
          </b-modal>
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'sales_department')
                .length > 0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
            @click="$refs['sales-department-modal-' + data.index].show()"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(operator)="data">
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'operator').length >
              0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(recoverer)="data">
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'recoverer').length >
              0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(operation_managment)="data">
          <b-modal
            :ref="'operation-managment-modal-' + data.index"
            :title="$t('Operation Managment')"
            hide-footer
          >
            <div class="m-1">
              <h4>Cities :</h4>
              <ul>
                <li v-for="city in data.item.cities">
                  {{ city.name }}
                </li>
              </ul>
            </div>
          </b-modal>
          <feather-icon
            v-if="
              data.item.roles.filter(
                (e) => e.codename === 'operation_managment'
              ).length > 0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
            @click="$refs['operation-managment-modal-' + data.index].show()"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(storehead)="data">
          <b-modal
            :ref="'storehead-modal-' + data.index"
            :title="$t('Storehead')"
            hide-footer
          >
            <div class="m-1">
              <h4>Physical Depots :</h4>
              <ul>
                <li v-for="physical_depot in data.item.physical_depots">
                  {{ physical_depot.name }}
                </li>
              </ul>
            </div>
          </b-modal>
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'storehead').length >
              0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
            @click="$refs['storehead-modal-' + data.index].show()"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(storekeeper)="data">
          <b-modal
            :ref="'storekeeper-modal-' + data.index"
            :title="$t('Storekeeper')"
            hide-footer
          >
            <div class="m-1">
              <h4>Physical Depots :</h4>
              <ul>
                <li v-for="physical_depot in data.item.physical_depots">
                  {{ physical_depot.name }}
                </li>
              </ul>
            </div>
          </b-modal>
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'storekeeper')
                .length > 0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
            @click="$refs['storekeeper-modal-' + data.index].show()"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(input)="data">
          <b-modal
            :ref="'input-modal-' + data.index"
            :title="$t('Input')"
            hide-footer
          >
            <div class="m-1">
              <h4>Physical Depots :</h4>
              <ul>
                <li v-for="physical_depot in data.item.physical_depots">
                  {{ physical_depot.name }}
                </li>
              </ul>
            </div>
          </b-modal>
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'input').length > 0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
            @click="$refs['input-modal-' + data.index].show()"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(delivery)="data">
          <b-modal
            :ref="'delivery-modal-' + data.index"
            :title="$t('Delivery')"
            hide-footer
          >
            <div class="m-1">
              <h4>Cities :</h4>
              <ul>
                <li v-for="city in data.item.cities">
                  {{ city.name }}
                </li>
              </ul>
            </div>
          </b-modal>
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'delivery').length >
              0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
            @click="$refs['delivery-modal-' + data.index].show()"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(seller)="data">
          <feather-icon
            v-if="
              data.item.roles.filter((e) => e.codename === 'seller').length >
              0
            "
            icon="CheckCircleIcon"
            size="21"
            class="text-success cursor-pointer"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="21"
            class="text-danger"
          />
        </template>
        <template #cell(actions)="data">
          <b-button
            @click="setUser(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="EditIcon" size="16" />
          </b-button>
          <b-button
            @click="setUserCaterory(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="Edit2Icon" size="16" />
          </b-button>
          <b-button
            @click="showConfirmMessageBox(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="Trash2Icon" size="16" />
          </b-button>
          <div class="text-nowrap">
            <!-- <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="LockIcon"
              class="cursor-pointer mr-2"
              size="16"
              @click="setUserRolerPermission(data.item)"
            /> -->
            <!----<feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="PlusIcon"
              variant="primary"
              class="btn-icon mr-1"
              size="16"
              @click="setUserCaterory(data.item)"
            />

            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="setUser(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showConfirmMessageBox(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>-->
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useUsersList";
import { ref } from "@vue/composition-api";
import UserAddNew from "./UserAddNew.vue";
import EditUser from "./EditUser.vue";
import EditUserRoleAndPermission from "./EditUserRoleAndPermission.vue";
import EditCategory from "./EditCategory.vue";
import Ripple from "vue-ripple-directive";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    UserAddNew,
    EditUserRoleAndPermission,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditUser,
    BTooltip,
    vSelect,
    EditCategory,
    BFormGroup,
  },
  async mounted() {
    try {
      const res = await instance.get("/roles/all/");
      this.roles = res.data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    roleClicked(role, user) {
      if (role === "sales_department") {
        return;
      }
    },
  },
  data() {
    return {
      roles: [],
    };
  },

  setup(props) {
    const isAddUserSidebarActive = ref(false);
    const isEditUserSidebarActive = ref(false);
    const isEditUserRoleAndPermissionSidebarActive = ref(false);
    const isEditUserCategoryActive = ref(false);
    const userRef = ref({});

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectedRole,
    } = useUsersList();

    function setUser(user) {
      isEditUserSidebarActive.value = true;
      userRef.value = user;
    }

    function setUserRolerPermission(user) {
      isEditUserRoleAndPermissionSidebarActive.value = true;
      userRef.value = user;
    }
    function setUserCaterory(user) {
      isEditUserCategoryActive.value = true;
      userRef.value = user;
      sessionStorage.setItem("user_id", user.id);
    }
    async function showConfirmMessageBox(user) {
      const agreed = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to delete it?"),
        {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (agreed) {
        try {
          await instance.delete(`/users/${user.id}`);
          refetchData();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("It has been deleted successfully"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: this.$t("You cannot delete this user"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    }

    return {
      // Sidebar
      isAddUserSidebarActive,
      isEditUserSidebarActive,
      isEditUserRoleAndPermissionSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      setUser,
      userRef,
      showConfirmMessageBox,
      setUserRolerPermission,
      // Filter
      avatarText,
      isEditUserCategoryActive,
      setUserCaterory,
      selectedRole,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
