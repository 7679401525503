<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <!-- <h5 class="mb-0">Edit user</h5> -->
        <h5 class="mb-0">
          {{ $t("Edit user") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="infoUser">
        <b-form class="p-2" @submit.prevent="editUser">
          <b-form-group label-for="first_name" :label="$t('First name')">
            <validation-provider
              #default="{ errors }"
              :name="$t('First name')"
              rules="required"
            >
              <b-form-input
                id="first_name"
                v-model="first_name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('First name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="last_name" :label="$t('Last name')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Last name')"
              rules="required"
            >
              <b-form-input
                id="last_name"
                v-model="last_name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Last name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="email" :label="$t('Email')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              rules="required"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="password" :label="$t('Password')">
            <validation-provider #default="{ errors }" :name="$t('Password')">
              <b-form-input
                id="password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Password')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="agent_id" :label="$t('Agent id')">
            <validation-provider #default="{ errors }" :name="$t('Agent_id')">
              <b-form-input
                id="Agent_id"
                v-model="Agent_id"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Agent id')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="is_active" :label="$t('Active')">
            <validation-provider #default="{ errors }" :name="$t('Active')">
              <b-form-checkbox
                id="is_active"
                v-model="is_active"
                class="custom-control-primary"
              >
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            >{{ $t("Edit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditUserSidebarActive",
    event: "update:is-edit-user-sidebar-active",
  },
  props: {
    isEditUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      is_active: this.user.is_active,
      Agent_id: this.user.agent_id,
      password: "",
    };
  },
  methods: {
    async editUser() {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Erreur"),
            text: this.$t("Invalid email"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      const success = await this.$refs.infoUser.validate();
      if (!success) return;

      try {
        await instance.put(`/users/${this.user.id}`, {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          is_active: this.is_active,
          agent_id: this.Agent_id,
          password: this.password,
        });

        this.$emit("refetch-data");
        this.$emit("update:is-edit-user-sidebar-active", false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been updated successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
