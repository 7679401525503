<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditUserRoleAndPermissionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="
      (val) =>
        $emit('update:is-edit-user-role-and-permission-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <!-- <h5 class="mb-0">Edit user</h5> -->
        <h5 class="mb-0">
          {{ $t("User roles and permissions") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div>
        <app-collapse class="p-1">
          <app-collapse-item
            v-for="role in user.roles"
            :key="role.id"
            :title="role.name"
          >
            <div v-if="role.codename === 'sales_department'">
              <h4>Categories :</h4>
              <ul>
                <li v-for="cate in user.categories">
                  {{ cate.name }}
                </li>
              </ul>
              <h4>Sub Profiles :</h4>
              <ul>
                <li v-for="sub in user.sub_profiles">
                  {{ sub.name }}
                </li>
              </ul>
            </div>
            <!----><div
              v-if="
                role.codename === 'delivery' ||
                role.codename === 'operation_managment'
              "
            >
              <h4>Cities :</h4>
              <ul>
                <li v-for="city in user.cities">
                  {{ city.name }}
                </li>
              </ul>
            </div>
            <div
              v-if="
                role.codename === 'storehead' ||
                role.codename === 'storekeeper' ||
                role.codename === 'input'
              "
            >
              <h4>Physical Depots :</h4>
              <ul>
                <li v-for="phy in user.physical_depots">
                  {{ phy.name }}
                </li>
              </ul>
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditUserRoleAndPermissionSidebarActive",
    event: "update:is-edit-user-role-and-permission-sidebar-active",
  },
  props: {
    isEditUserRoleAndPermissionSidebarActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      roles: [],
      permissions: [],
      selectedRoles: this.user.roles,
      selectedPermissions: this.user.permissions,
    };
  },
  async beforeCreate() {
    try {
      const res = await Promise.all([
        instance.get("/roles/all/"),
        instance.get("/permissions/all/"),
      ]);
      this.roles = res[0].data;
      this.permissions = res[1].data;
    } catch (err) {
      const error = err.response
        ? Object.values(err.response.data)[0][0]
        : err.message;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: error ?? this.$t("An error has occurred. Please try again"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    async editRolesPermissions() {
      const success = await this.$refs.infoUser.validate();
      if (!success) return;

      try {
        await instance.put(`/users/${this.user.id}/roles-permissions/`, {
          permissions: this.selectedPermissions,
          roles: this.selectedRoles,
        });
        this.$emit("refetch-data");
        this.$emit(
          "update:is-edit-user-role-and-permission-sidebar-active",
          false
        );

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been updated successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
