<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditUserCategoryActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-user-category-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <!-- <h5 class="mb-0">Edit user</h5> -->
        <h5 class="mb-0">
          {{ $t("Edit role") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <div class="p-2">
        <b-form-group :label="$t('Role')" label-for="roles">
          <v-select
            id="roles"
            v-model="selectedRole"
            :options="roles"
            label="name"
          />
        </b-form-group>
        <div v-if="selectedRole">
          <div v-if="selectedRole.codename == 'sales_department'">
            <b-form-group :label="$t('Categories')" label-for="catego">
              <v-select
                id="catego"
                multiple
                label="name"
                v-model="selectedCategories"
                :options="categories"
              />
            </b-form-group>
            <b-form-group :label="$t('Sub profile')" label-for="sub_profile">
              <v-select
                id="sub_profile"
                multiple
                label="name"
                v-model="selectedSubProfiles"
                :options="subProfiles"
              />
            </b-form-group>
          </div>
          <!----<div v-if="selectedRole.codename == 'operation_managment'">
            <b-form-group :label="$t('City')" label-for="city">
              <v-select
                id="city"
                label="name"
                multiple
                v-model="selectedOperationManagmentCity"
                :options="cities"
              />
            </b-form-group>
          </div>
          <div v-if="selectedRole.codename == 'storehead'">
            <b-form-group
              :label="$t('Physical depot')"
              label-for="physical_depot"
            >
              <v-select
                id="physical_depot"
                label="name"
                multiple
                v-model="selectedStoreHeadPhysicalDepot"
                :options="physicalDepots"
              />
            </b-form-group>
          </div>
          <div v-if="selectedRole.codename == 'storekeeper'">
            <b-form-group
              :label="$t('Physical depot')"
              label-for="physical_depot"
            >
              <v-select
                id="physical_depot"
                label="name"
                multiple
                v-model="selectedStoreKeeperPhysicalDepot"
                :options="physicalDepots"
              />
            </b-form-group>
          </div>
          <div v-if="selectedRole.codename == 'delivery'">
            <b-form-group :label="$t('City')" label-for="city">
              <v-select
                id="city"
                multiple
                label="name"
                v-model="selectedDeleveryCity"
                :options="cities"
              />
            </b-form-group>
          </div>-->
          <div v-if="selectedRole.codename == 'input'">
            <b-form-group
              :label="$t('Depot')"
              label-for="depot"
            >
              <v-select
                id="depot"
                multiple
                label="name"
                v-model="selectedInputPhysicalDepot"
                :options="depots"
              />
            </b-form-group>
          </div>
        </div>

        <b-row class="pl-2">
          <b-button
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            @click="editUser"
            class="mr-1"
            >{{ $t("Add role") }}</b-button
          >
          <b-button
            variant="danger"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            @click="deleteRole"
            v-if="
              selectedRole &&
              user.roles.filter((e) => e.codename === selectedRole.codename)
                .length > 0
            "
            >{{ $t("Delete role") }}</b-button
          >
        </b-row>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditUserCategoryActive",
    event: "update:is-edit-user-category-active",
  },
  props: {
    isEditUserCategoryActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      categories: [],
      subProfiles: [],
      roles: [],
      physicalDepots: [],
      cities: [],
      depots:[],
      selectedRole: null,
      selectedOperationManagmentCity: this.user.cities,
      selectedDeleveryCity: this.user.cities,
      selectedStoreHeadPhysicalDepot: this.user.physical_depots,
      selectedStoreKeeperPhysicalDepot: this.user.physical_depots,
      selectedInputPhysicalDepot: [],
      selectedCategories: this.user.categories,
      selectedSubProfiles: this.user.sub_profiles,
    };
  },
  async beforeCreate() {
    var user_id = sessionStorage.getItem("user_id");
    try {
      const res = await Promise.all([
        instance.get("/parameters/categories/all/"),
        instance.get("/parameters/subprofiles/"),
        instance.get("/roles/all/"),
        instance.get("/parameters/physical-depots/"),
        instance.get("/parameters/get-all-depots/"),
        instance.get("/parameters/cities/all/"),
        instance.get(`/parameters/${user_id}/user-depots/`),
      ]);
      this.categories = res[0].data;
      this.subProfiles = res[1].data;
      this.roles = res[2].data;
      this.physicalDepots = res[3].data;
      this.depots = res[4].data;
      this.cities = res[5].data;
      this.selectedInputPhysicalDepot = res[6].data
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },

  methods: {
    async deleteRole() {
      try {
        if (this.selectedRole.codename === "sales_department") {
          await instance.put(`/users/${this.user.id}/categories-subprofile/`, {
            categories: [],
            sub_profiles: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }
        if (this.selectedRole.codename === "storehead") {
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }
        if (this.selectedRole.codename === "storekeeper") {
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }
        if (this.selectedRole.codename === "seller") {
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }
        if (this.selectedRole.codename === "input") {
          await instance.put(`/users/${this.user.id}/depots/`, {
            depots: [],
          });
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }

        if (this.selectedRole.codename === "delivery") {
          await instance.put(`/users/${this.user.id}/cities/`, {
            cities: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }

        if (this.selectedRole.codename === "operation_managment") {
          await instance.put(`/users/${this.user.id}/cities/`, {
            cities: [],
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
          });
        }

        if (
          this.selectedRole.codename === "operator" ||
          this.selectedRole.codename === "recoverer"
        ) {
          await instance.put(`/users/${this.user.id}/roles-permissions/`, {
            roles: this.user.roles.filter((e) => e.id !== this.selectedRole.id),
            permissions: [],
          });
        }
        this.$emit("refetch-data");
        this.$emit("update:is-edit-user-category-active", false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("The role has been deleted successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async editUser() {
      if (!this.selectedRole) return;

      try {
        if (this.selectedRole.codename === "sales_department") {
          await instance.put(`/users/${this.user.id}/categories-subprofile/`, {
            categories: this.selectedCategories,
            sub_profiles: this.selectedSubProfiles,
            roles: [...this.user.roles, this.selectedRole],
          });
        }
        if (this.selectedRole.codename === "storehead") {
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: this.selectedStoreHeadPhysicalDepot,
            roles: [...this.user.roles, this.selectedRole],
          });
        }
        if (this.selectedRole.codename === "storekeeper") {
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: this.selectedStoreKeeperPhysicalDepot,
            roles: [...this.user.roles, this.selectedRole],
          });
        }
        if (this.selectedRole.codename === "seller") {
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: this.selectedStoreKeeperPhysicalDepot,
            roles: [...this.user.roles, this.selectedRole],
          });
        }
        if (this.selectedRole.codename === "input") {
          await instance.put(`/users/${this.user.id}/depots/`, {
            depots: this.selectedInputPhysicalDepot,
          });
          await instance.put(`/users/${this.user.id}/physical-depots/`, {
            physical_depots: [],
            roles: [...this.user.roles, this.selectedRole],
          });
        }

        if (this.selectedRole.codename === "delivery") {
          await instance.put(`/users/${this.user.id}/cities/`, {
            cities: this.selectedDeleveryCity,
            roles: [...this.user.roles, this.selectedRole],
          });
        }

        if (this.selectedRole.codename === "operation_managment") {
          await instance.put(`/users/${this.user.id}/cities/`, {
            cities: this.selectedOperationManagmentCity,
            roles: [...this.user.roles, this.selectedRole],
          });
        }

        if (
          this.selectedRole.codename === "operator" ||
          this.selectedRole.codename === "recoverer"
        ) {
          await instance.put(`/users/${this.user.id}/roles-permissions/`, {
            roles: [...this.user.roles, this.selectedRole],
            permissions: [],
          });
        }

        this.$emit("refetch-data");
        this.$emit("update:is-edit-user-category-active", false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been updated successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
