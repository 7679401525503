var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isEditUserRoleAndPermissionSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-edit-user-role-and-permission-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("User roles and permissions"))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('div',[_c('app-collapse',{staticClass:"p-1"},_vm._l((_vm.user.roles),function(role){return _c('app-collapse-item',{key:role.id,attrs:{"title":role.name}},[(role.codename === 'sales_department')?_c('div',[_c('h4',[_vm._v("Categories :")]),_c('ul',_vm._l((_vm.user.categories),function(cate){return _c('li',[_vm._v(" "+_vm._s(cate.name)+" ")])}),0),_c('h4',[_vm._v("Sub Profiles :")]),_c('ul',_vm._l((_vm.user.sub_profiles),function(sub){return _c('li',[_vm._v(" "+_vm._s(sub.name)+" ")])}),0)]):_vm._e(),(
              role.codename === 'delivery' ||
              role.codename === 'operation_managment'
            )?_c('div',[_c('h4',[_vm._v("Cities :")]),_c('ul',_vm._l((_vm.user.cities),function(city){return _c('li',[_vm._v(" "+_vm._s(city.name)+" ")])}),0)]):_vm._e(),(
              role.codename === 'storehead' ||
              role.codename === 'storekeeper' ||
              role.codename === 'input'
            )?_c('div',[_c('h4',[_vm._v("Physical Depots :")]),_c('ul',_vm._l((_vm.user.physical_depots),function(phy){return _c('li',[_vm._v(" "+_vm._s(phy.name)+" ")])}),0)]):_vm._e()])}),1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }